<template>
    <ion-page>         
        <ion-content class="ion-padding">
            <ion-grid>
                <ion-row>
                    <ion-col class="ion-justify-content-start">
                        <SearchInput
                            name="search"
                            placeholder="Search"
                            v-model="formData.search.value"
                            class="dink-search"
                            :class="this.formData.search.classes"
                            ref="input"
                            @searchCallback="searchCallbackHandler"
                        ></SearchInput>
                    </ion-col>
                </ion-row>       
                <ion-row v-if="errorMessage">
                    <ion-col>
                        <div
                            class="error ion-margin-bottom ion-margin-horizontal"
                        >{{ errorMessage }}
                        </div>
                    </ion-col>
                </ion-row> 
                <ion-row>
                    <ion-col class="search-results" v-if="results && results.length > 0">
                        <FeedBubble v-for="friend in results" 
                            :id="friend.id"
                            :title= "friend.username"
                            :subtitle="friend.biography"
                            :icons="{
                                goto: false,
                                msg: false,
                                friendAdd: !friend.is_friend,
                            }"
                            @click="handleFriendClick(friend.id)"
                            @addFriendCallback="handleAddFriendCallback"
                        ></FeedBubble>             
                    </ion-col>
                    <ion-col v-else-if="results && results.length == 0" class="ion-padding">
                        No search results
                    </ion-col>
                    <ion-col v-else class="ion-padding">
                        <img src="/img/banners/banner-search-2.png"  class="banner-image">
                    </ion-col>
                </ion-row>
            </ion-grid>
            <ion-toast 
                :is-open="toastOpen" 
                :message="successMessage" 
                :duration="2000"
                position="bottom"
                :icon="toastIcon"
                position-anchor="ionTabFooter"
                class="success-toast"
                @didDismiss="setToast(false)"
            ><ion-img slot="image" :src="imageUrl" /></ion-toast>      
        </ion-content>    
    </ion-page>
</template>

<script>
import { IonPage, IonNav, IonContent, IonTabs, IonGrid, IonCol, IonRow, IonHeader, 
   IonChip, IonToast, IonImg } from '@ionic/vue';
import { peopleCircleOutline } from 'ionicons/icons';
import Footer from '@/components/FooterButton.vue';
import AvatarToolbar from '@/components/ui/AvatarToolbar.vue';
import SearchInput from '@/components/forms/SearchInput.vue';
import FormVerification from '@/composables/verification.js'
import FeedBubble from '@/components/match/FeedBubble.vue';
import { mapActions } from 'vuex';

const { displayErrorMessage, validateForm } = FormVerification();

export default {
    name: 'My Matches',
    components: {
        IonPage, IonNav, Footer, AvatarToolbar, IonContent, IonTabs, IonGrid, IonCol, IonRow, 
        IonHeader, IonChip, IonToast, IonImg, SearchInput, FeedBubble
    },

    data() {
        return {
            formData: {
                search: {
                    value: null,
                    rules: [
                        (v) => !!v || 'Search input is a required field to search'
                    ],
                    error: {
                        messages: []
                    },
                    classes: {
                        'error': false
                    }
                },
            },
            results: null,
            errorMessage: null,
            successMessage: null,
            toastOpen: false,
            imageUrl: "/img/icons/icon-248x248.png",
            toastIcon: peopleCircleOutline
        }   
  },

  methods: {
        ...mapActions({
            search: 'users/search',
        }),

        async searchCallbackHandler(){
            if(!validateForm(this.formData)){
                this.errorMessage = displayErrorMessage(this.formData);
                return false;
            }

            const r = await this.fetchSearch(this.formData.search.value);

            this.results = r;
        },

        async fetchSearch(keyword){
            var data = [];
            
            const response = await this.search({ 
                search: keyword 
            })

            data = response.data.results;

            return data;
        },

        handleFriendClick(id){
            this.$router.push(`/profile/${id}`);   
        },

        handleAddFriendCallback(r){
            this.successMessage = `Friend request sent.`;
            this.setToast(true);
        },

        setToast(state, icon = peopleCircleOutline) {
            this.toastIcon = icon;
            this.toastOpen = state;
        }
    }
}
</script>

<style scoped>
.search-results {
    display: flex;
    flex-direction: column;
    padding-right: 8px;
}
</style>