<template>
    <ion-page>
        <ion-header collapse="fade" class="ion-no-border ion-margin-top">
            <ProfileToolbar
                :id="id"
                :showMessageBtn="!isMe"
                :showEditBtn="isMe"
                defaultHref="/matches/schedule"
            ></ProfileToolbar>
        </ion-header>            
        <ion-content class="ion-padding">
            <Profile
                :profile="profile"
                court="Fishers"
            ></Profile>
            <ion-grid>
                <ion-row>
                    <ion-col class="ion-justify-content-center">
                        <ion-button
                            v-if="isMe"
                            type="submit"
                            shape="round"
                            expand="block"
                            class="logout-btn"
                            @click="logout()"
                        >Logout</ion-button>
                    </ion-col>
                </ion-row>
            </ion-grid>
        </ion-content>
    </ion-page>
</template>

<script>
import { IonPage, IonNav, IonContent, IonTabs, IonGrid, IonCol, IonRow, IonHeader, 
   IonChip, IonIcon, IonButton } from '@ionic/vue';
import { ref } from 'vue';
import { mapGetters, mapActions, useStore } from 'vuex';
import ProfileToolbar from '@/components/ui/ProfileToolbar.vue';
import Profile from '@/components/user/Profile.vue';

export default {
    name: 'FriendProfile',
    components: {
        IonPage, IonNav, ProfileToolbar, IonContent, IonTabs, IonGrid, IonCol, IonRow, IonHeader, 
        IonChip, IonIcon, Profile, IonButton, ref, mapGetters, mapActions, useStore
    },

    props: {
        profileId: {
            type: Number,
            default: null,
        },
    },

    data() {
        return {
            profile: null,
        };
    },

    async mounted() {
        await this.getUser();
        this.profile = await this.getProfileById(this.id);
    },

    computed: {
        ...mapGetters({
            me: 'auth/me',
        }),

        id() {
            // Prefer the prop.
            if (this.profileId) {
                return this.profileId;
            }

            if (this.$route.params.id) {
                return this.$route.params.id;
            }

            return this.me?.id;
        },

        isMe() {
            return this.me?.id == this.id;
        },
    },

    methods: {
        ...mapActions({
            getProfileById: 'profiles/get',
            getUser: 'auth/whoami',
        }),

        async logout() {
            await this.$store.dispatch('auth/logout');
            this.$router.push('/login');
        },
    }
}
</script>

<style scoped>
.btn-header {
    width: auto;
}

.logout-btn {
    --background: #FF3333;
}
</style>