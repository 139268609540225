import api from '@/services/api';

const token = localStorage.getItem('token');

const auth = {
  namespaced: true,

  state() {
    return {
      user: null,
      token,
    };
  },

  getters: {
    isAuthenticated(state) {
      return Boolean(state.token);
    },

    token(state) {
      return state.token;
    },

    me(state) {
      return state.user;
    },

    userId(state) {
      return state.user?.id;
    },
  },

  actions: {
    async login(_, { email, mobile }) {
      const credentials = email ? { email } : { mobile } ;
      const r = await api.post('/security/auth/login/', credentials );
      return r;
    },

    async verifyCode({ commit }, params) {
      const r = await api.post('/security/auth/login/', params);

      // delete all cookies (to emulate app environment when potentially
      // in browser).
      try {
        document.cookie = 'sessionid=; Max-Age=-99999999;';
        document.cookie = 'csrftoken=; Max-Age=-99999999;';
        document.cookie = 'auth_token=; Max-Age=-99999999;';

      } catch (e) {
        console.error(e);
      }

      commit('SET_TOKEN', r.data['token']);
      commit('SET_USER', r.data['user'])

      return r;
    },

    async logout({ commit }) {
      const r = await api.delete('/security/auth/login/');
      commit('SET_USER', false);
      commit('SET_TOKEN', null);

      return r;
    },

    async whoami({ commit, state }) {
      // NOTE: false indicates that a previous request failed. While null
      // indicates that a request has not yet been made. IOW, false is a
      // negative indicator.
      if (state.user === false) {
        return null;
      } else if (state.user !== null) {
        return state.user;
      }

      try {
        const r = await api.get('/user/whoami/');
        commit('SET_USER', r.data);
        return state.user;

      } catch (err) {
        commit('SET_USER', false);
        throw err;
      }
    },
  },

  mutations: {
    SET_USER(state, user) {
      state.user = user;
    },

    SET_TOKEN(state, token) {
      state.token = token;
      localStorage.setItem('token', token);

      const socket = this.$socket.io;
      const manager = socket.io;

      if (!socket || !manager) {
        return;
      }

      if (token) {
        socket.connect();
        manager.reconnection(true);
      } else {
        socket.disconnect();
        manager.reconnection(false);
      }
    }
  }
};

export default auth;
