<template>
    <Header
        logo="white"
        type="logo"
        defaultHref="/login"
        @backButtonCallback="$emit('back')"
    ></Header>
    <ion-content class="ion-padding">
      <form>
        <h2 class="ion-text-center">Tell Us About Yourself</h2>
        <DefaultInput
            name="firstname"
            label="first name"
            placeholder="Enter your first name"
            v-model="this.formData.firstname.value"
            class="ion-margin-bottom"
            :value="firstname"
            :class="this.formData.firstname.classes"
        ></DefaultInput>
        <DefaultInput
            name="lastname"
            label="last name"
            placeholder="Enter your last name"
            v-model="this.formData.lastname.value"
            class="ion-margin-bottom"
            :value="lastname"
            :class="this.formData.lastname.classes"
        ></DefaultInput>
        <EmailInput
            v-model.lazy="this.formData.email.value"
            class="ion-margin-bottom"
            :class="this.formData.email.classes"
            :message="this.errorMessage"
            @update-error-message="handleErrorMessage"
            :value="formattedEmail" 
            ref="emailInput"
        ></EmailInput>
        <PhoneNumberInput
            v-model.lazy="this.formData.mobile.value"
            class="ion-margin-bottom"
            :class="this.formData.mobile.classes"
            :message="this.errorMessage"
            @update-error-message="handleErrorMessage" 
            :value="formattedMobile"
            ref="phoneNumberInput" 
        ></PhoneNumberInput>
        <UsernameInput
            v-model.lazy="this.formData.username.value"
            class="ion-margin-bottom"
            :class="this.formData.username.classes"
            :message="this.errorMessage"
            :value="username"
            @update-error-message="handleErrorMessage" 
            ref="usernameInput"
        ></UsernameInput>
        <DateTimePicker
            name="dob"
            label="Date of Birth"
            v-model="this.formData.dob.value"
            class="ion-margin-bottom"
            :class="this.formData.dob.classes"
            :value="currentDate"
            :wheel=true
            @update-error-message="handleErrorMessage" 
        ></DateTimePicker>    
        <div
            v-if="errorMessage"
            class="error ion-margin-bottom ion-margin-horizontal"
        >{{ errorMessage }}</div>
      </form>
    </ion-content>
    <Footer
        buttonText="Next"
        @click="submitUserDetails"
    ></Footer>
</template>

<script>
import { IonPage, IonContent, IonDatetime } from '@ionic/vue';
import { mapActions } from 'vuex';
import moment from 'moment'
import DefaultInput from '@/components/forms/DefaultInput.vue';
import EmailInput from '@/components/forms/EmailInput.vue';
import PhoneNumberInput from '@/components/forms/PhoneNumberInput.vue';
import UsernameInput from '@/components/forms/UsernameInput.vue';
import DateTimePicker from '@/components/forms/DateTimePicker.vue'
import Header from '@/components/Header.vue';
import Footer from '@/components/FooterButton.vue';
import FormVerification from '@/composables/verification.js'
import * as validator from 'email-validator';

const { displayErrorMessage, validateForm, isValidPhone, isOfAge, cleanPhone } = FormVerification();

export default {
  name: 'More Details',

  emits:['register','back'],

  components: {
        IonPage, IonContent, IonDatetime, Header, Footer, DefaultInput, EmailInput, PhoneNumberInput, 
        UsernameInput, DateTimePicker, FormVerification
  },
  
  props: {
    email: String,
    mobile: String,
    firstname: String,
    lastname: String,
    username: String
  },

  computed: {
    formattedMobile() {
      var clean = ( this.mobile || '');

      // Remove leading + if one exist
      clean = clean.replace(/^[\+]/, '');  
      // Remove leading 1 if one exist
      clean = clean.replaceAll(/^1/g, '');
      // Apply phone ###-###-### formatting
      clean = clean.replace(/^(\d{3})(\d{3})(\d{4})$/, '($1) $2-$3');

      return clean;
    },

    formattedEmail() {
      return this.email || '';
    },

    currentDate() {
      return moment().format('YYYY-MM-DD');
    }
  },

  mounted() {
    this.formData.mobile.value = this.formattedMobile;
    this.formData.email.value = this.formattedEmail;
  },

  data() {
    return {
      formData: {
        firstname: {
          value: '',
          rules: [
            (v) => !!v || 'First name is a required field',
          ],
          error: {
            messages: []
          },
          classes: {
            'error': false
          }
        },
        lastname: {
          value: '',
          rules: [
            (v) => !!v || 'Last name is a required field',
          ],
          error: {
            messages: []
          },
          classes: {
            'error': false
          }
        },
        mobile: {
          value: this.formattedMobile,
          rules: [
            (v) => !!v || 'Phone number is a required field',
            (v) => isValidPhone(v) || 'A valid phone number must be used'
          ],
          error: {
            messages: []
          },
          classes: {
            'error': false
          }
        },
        email: {
          value: this.formattedEmail,
          rules: [
            (v) => !!v || 'Email is a required field',
            (v) => validator.validate(v) || 'Email must be valid'
          ],
          error: {
            messages: []
          },
          classes: {
            'error': false
          }
        },
        username: {
          value: '',
          rules: [
            (v) => !!v || 'Nickname is required',
            (v) => !this.checkWhiteSpace(v) || 'Nickname cannot have spaces.'    
          ],
          error: {
            messages: []
          },
          classes: {
            'error': false
          }
        },
        dob: {
          value: '',
          rules: [
            (v) => !!v || 'Date of birth is a required field',
            (v) => isOfAge(v) || 'Must be over 18'
          ],
          error: {
            messages: []
          },
          classes: {
            'error': false
          }
        },
      },
      errorMessage: null
    }
  },

  methods: {
    ...mapActions({
      register:'users/register',
    }),

    async submitUserDetails() {
      if(!validateForm(this.formData)){
        this.errorMessage = displayErrorMessage(this.formData);
        return false;
      }

      // Unique email check
      if(!await this.$refs.emailInput.validEmail(this.formData.email.value)){
        return false;
      }

      // Unique phone number check
      if(!await this.$refs.phoneNumberInput.validPhone(this.formData.mobile.value)){
        return false;
      }

      // Unique username check
      if(!await this.$refs.usernameInput.validUsername(this.formData.username.value)){
        return false;
      }

      //Clean phone for api
      this.formData.mobile.value = cleanPhone(this.formData.mobile.value);

      try {
        const r = await this.register({
          'first_name': this.formData.firstname.value,
          'last_name': this.formData.lastname.value,
          'username': this.formData.username.value,
          'email': this.formData.email.value,
          'mobile': this.formData.mobile.value,
          'profile': {
            'birth_date': this.formData.dob.value
          }
        });

        this.$emit('register', { nonce: r.data.nonce });

      } catch (e) {
        this.handleErrorMessage(e.response.message);
      }
    },
    
    handleErrorMessage(message){
      this.errorMessage = '';
      this.errorMessage = message;
    },

    checkWhiteSpace(s) {
      return s.indexOf(' ') >= 0;
    }
  }
}
</script>