import api from '@/services/api';

const user = {
    namespaced: true,

    actions: {
        async update({ commit, rootState }, params){
            const user = rootState.auth.user;
            
            if (!user) {
                return;
            }

            const r = await api.patch(`/user/users/${user.id}/`, params)
            commit('auth/UPDATE_USER', r.data, { root: true });
            return r;
        },

        async inviteFriend(_, params) {
            const r = await api.post('/user/invites/', params).catch(error => {
                if (error.response?.status) {
                    throw new Error(error.response.status);
                }
                throw error;
            });
            
            return r;
        },

        async search(_, params ) {     
            const r = await api.get(`/user/users/`, { params: params });
            return r;
        },

        async checkUnique(_, params) {
            const r = await api.get("/user/check/", {
              params: params,
              disableSpinner: true
            })
      
            return r;
        },
      
          async register(_, params) {
            const r = await api.post('/user/users/', params);
            return r;
        },      
    }
}

export default user;