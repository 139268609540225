<template>
    <ion-page>       
        <ion-content class="ion-padding">
            <ion-grid class="ion-padding-bottom">
                <ion-row>
                    <ion-col size="8" class="ion-wrap">
                        <h2 class="ion-padding-horizontal">Schedule Match</h2>
                        <h6 class="ion-padding-horizontal">Host: You</h6>
                    </ion-col>
                    <ion-col class="ion-align-items-center">
                        <ion-chip class="btn-add-schedule">+ Schedule</ion-chip>
                    </ion-col>
                </ion-row>
            </ion-grid>
            <ion-grid>
                <ion-row>
                    <ion-col>
                        <ion-button
                            type="submit" 
                            shape="circle" 
                            expand="block" 
                            class="btn-check-in"
                        ><ion-icon src="/img/icons/ui/msg-chat-circle.svg" class="item-icon" slot="icon-only"></ion-icon></ion-button>
                    </ion-col>
                    <ion-col class="ion-align-items-center">
                        <ion-chip class="btn-add-schedule"><ion-icon src="/img/icons/ui/edit-icon.svg" class="chip-icon"></ion-icon> Edit</ion-chip>
                    </ion-col>
                </ion-row>
            </ion-grid>  
            <MatchDetail></MatchDetail>    
        </ion-content>
        <Footer
            class="offset-tabbar alert"
            buttonText="Cancel"
        ></Footer>
    </ion-page> 
</template>

<script>
import { IonPage, IonNav, IonContent, IonGrid, IonCol, IonRow, IonHeader, 
   IonChip, IonIcon, IonButton } from '@ionic/vue';
import Footer from '@/components/FooterButton.vue';
import AvatarToolbar from '@/components/ui/AvatarToolbar.vue';
import MatchChip from '@/components/ui/nav/MatchChip.vue';
import MatchDetail from '@/components/match/Detail.vue';

export default {
    name: 'MatchDetails',
    components: {
        IonPage, IonNav, Footer, AvatarToolbar, IonContent, IonGrid, IonCol, IonRow, 
        IonHeader, IonChip, IonIcon, IonButton, MatchDetail, MatchChip
    }
}
</script>

<style scoped>
ion-button {
    width: auto;
}

.btn-header {
    width: auto;
    --background: $ffffff;
}

.btn-add-schedule {
    --background: #044784;
    --color: #fff;
    height: 32px;
    margin-left: auto;
}

ion-button[shape=circle] {
    --border-radius: 50%;
    width: 50px;
    height: 50px;
}

.chip-icon {
    margin-left: 0px;
    margin-right: 4px;
}

h2, h6 {
    margin-top: 0px;
    margin-bottom: 0px;
    line-height: 30px;
}

h6 {
    line-height: 20px;
    color: rgba(0, 0, 0, 0.5);
}
</style>