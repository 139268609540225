<template>
    <ion-page>        
        <ion-content class="ion-padding">
            <ion-grid>
                <ion-row>
                    <ion-col size="7">
                        <h2 class="ion-padding-horizontal">All Matches</h2>
                    </ion-col>
                    <ion-col size="5">
                        <ion-chip class="btn-add-schedule">+ Schedule A Match</ion-chip>
                    </ion-col>
                </ion-row>
            </ion-grid>
            <MatchSchedule></MatchSchedule>       
        </ion-content>
    </ion-page>    
</template>

<script>
import { IonPage, IonNav, IonContent, IonTabs, IonGrid, IonCol, IonRow, IonHeader, 
   IonChip } from '@ionic/vue';
import Footer from '@/components/FooterButton.vue';
import AvatarToolbar from '@/components/ui/AvatarToolbar.vue';
import MatchChip from '@/components/ui/nav/MatchChip.vue';
import MatchSchedule from '@/components/match/Schedule.vue';

export default {
    name: 'My Matches',
    components: {
        IonPage, IonNav, Footer, AvatarToolbar, IonContent, IonTabs, IonGrid, IonCol, IonRow, 
        IonHeader, IonChip, MatchSchedule, MatchChip
    }
}
</script>

<style scoped>
ion-button {
    width: auto;
}

.btn-header {
    width: auto;
    --background: $ffffff;
}

.btn-add-schedule {
    --background: #044784;
    --color: #fff;
    height: 32px;
    margin-left: auto;
    opacity: 0.4;
}

h2 {
    margin-top: 0px;
    line-height: 30px;
}
</style>