<template>
    <ion-page>
        <ion-header collapse="fade" class="ion-no-border ion-margin-top">
            <ProfileToolbar
                :showMessageBtn=false
                :showEditBtn=false
                defaultHref="/profile" 
            ></ProfileToolbar>
        </ion-header>            
        <ion-content class="ion-padding">
            <h2 class="ion-text-center">Update your profile</h2> 
            <ProfileForm v-if="user"
                :emailAddress="formData.email.value"
                :mobile="formData.mobile.value"
                :firstname="formData.firstname.value"
                :lastname="formData.lastname.value"
                :username="formData.username.value"
                :dob="formData.dob.value"
                :biography="formData.biography.value"
                buttonText="Submit"
                @formCallback="handleFormCallback"
                @backButtonCallback="handleBackButtonCallback"
            ></ProfileForm>
            <div
                v-if="errorMessage"
                class="error ion-margin-bottom ion-margin-horizontal"
            >{{ errorMessage }}</div>
            <ion-toast 
                :is-open="toastOpen" 
                :message="successMessage" 
                :duration="2000"
                position="bottom"
                :icon="toastIcon"
                position-anchor="footer"
                class="success-toast"
                @didDismiss="setToast(false)"
            ><ion-img slot="image" :src="imageUrl" /></ion-toast>
        </ion-content>
    </ion-page>
</template>

<script>
import { IonPage, IonNav, IonContent, IonTabs, IonGrid, IonCol, IonRow, IonHeader, 
   IonChip, IonIcon, IonToast, IonImg } from '@ionic/vue';
import { ref, computed, onMounted  } from 'vue';
import { mapGetters, mapActions, useStore } from 'vuex';
import { peopleCircleOutline  } from 'ionicons/icons';
import ProfileToolbar from '@/components/ui/ProfileToolbar.vue';
import ProfileForm from '@/components/forms/UserProfileForm.vue';

export default {
    name: 'FriendProfile',
    components: {
        IonPage, IonNav, ProfileToolbar, IonContent, IonTabs, IonGrid, IonCol, IonRow, IonHeader, 
        IonChip, IonIcon, IonToast, IonImg, ProfileForm, ref, mapGetters, mapActions, useStore
    },

    data() {
      return {
        formData:{
            firstname: {
                value: '',
                rules: [
                    (v) => !!v || 'First name is required',
                ]
            },
            lastname: {
                value: '',
                rules: [
                    (v) => !!v || 'Last name is required',
                ],
            },
            email: {
                value: '',
                rules: [
                    (v) => validator.validate(v) || 'Email must be valid',
                ],
            },
            mobile: {
                value: '',
                rules: [
                    (v) => !!v || 'mobile is required',
                ],
            },
            username: {
                value: '',
                rules: [
                    (v) => !!v || 'Username is required',
                ],
            },
            dob: {
                value: '',
                rules: [
                    (v) => !!v || 'Date of birth is required',
                ],
            },
            biography: {
                value: '',
                rules: [
                    (v) => !!v || 'Biography is required',
                ],
            }
        },
        user: null,
        messageBtn: false,
        editBtn: false,
        errorMessage: "",
        successMessage: null,
        toastOpen: false,
        imageUrl: "/img/icons/icon-248x248.png",
        toastIcon: peopleCircleOutline
      }
    },

    async mounted() {
        const user = ref(null);

        this.user = await this.whoami();

        this.formData.firstname.value = this.user.first_name;
        this.formData.lastname.value = this.user.last_name;
        this.formData.email.value = this.user.email;
        this.formData.mobile.value = this.user.mobile;
        this.formData.username.value = this.user.username;
        this.formData.dob.value = this.user.profile.birth_date;
        this.formData.biography.value = this.user.profile.biography;
    },

    computed: {
        rating() {
            return this.user.rating?.rating || "0";
        },

        firstname() {
            return this.formData.firstname.value || "";
        },

        username() {
            return this.formData?.username.value || "";
        },
    },

    methods: {
        ...mapActions({
            whoami: 'auth/whoami',
            profilePatch: 'profiles/update',
            userPatch: 'users/update',
        }),

        async handleFormCallback(response){
            // Return the matching form data from more details
            this.formData = response;
            this.errorMessage = '';

            //Build user object
            var userOptions = {};
            var responseUser = false;

            if(this.formData.firstname.value != this.formData.firstname.oldValue){
                userOptions.first_name = this.formData.firstname.value;
            }

            if(this.formData.lastname.value != this.formData.lastname.oldValue){
                userOptions.last_name = this.formData.lastname.value;
            }

            if(this.formData.mobile.value != this.formData.mobile.oldValue){
                userOptions.mobile = this.formData.mobile.value;
            }

            if(this.formData.email.value != this.formData.email.oldValue){
                userOptions.email = this.formData.email.value;
            }

            if(this.formData.username.value != this.formData.username.oldValue){
                userOptions.username = this.formData.username.value;
            }              

            //Build user and profile object
            var profileOptions = {};
            var responseProfile = false;

            if(this.formData.biography.value != this.formData.biography.oldValue){
                profileOptions.biography = this.formData.biography.value;
            }

            if(this.formData.dob.value != this.formData.dob.oldValue){
                profileOptions.birth_date = this.formData.dob.value;
            }    

            // Patch if there are user option changes
            if(Object.keys(userOptions).length > 0){
                responseUser = await this.userPatch(userOptions);
            }

            // Patch if there are profile option changes
            if(Object.keys(profileOptions).length > 0){
                responseProfile = await this.profilePatch(profileOptions);
            }

            if(!responseProfile && !responseUser) {
                this.successMessage = `There were no changes to your profile.`;
                this.setToast(true);
                return true;
            } else if (responseUser?.status || responseProfile?.status){
                if ((200 <= responseUser.status && responseUser.status < 300) 
                    && (200 <= responseProfile.status && responseProfile.status < 300)) {
                    this.successMessage = `Your profile and user information has been updated.`;
                    this.setToast(true);
                    return true;
                } else if(200 <= responseUser.status && responseUser.status < 300){
                    this.successMessage = `Your user information has been updated.`;
                    this.setToast(true);
                    return true;
                } else if(200 <= responseProfile.status && responseProfile.status < 300){
                    this.successMessage = `Your profile has been updated.`;
                    this.setToast(true);
                    return true;
                } else {
                    this.errorMessage = `Error updating user please try again`;
                    return false;
                }
            } else {
                this.errorMessage = `Error updating user please try again`;
                return false;
            }
        },

        handleBackButtonCallback(r) {
            this.$router.back();
        },

        setToast(state, icon = peopleCircleOutline) {
            this.toastIcon = icon;
            this.toastOpen = state;
        }
    }
}
</script>

<style scoped>
.btn-header {
    width: auto;
    --background: $ffffff;
}

label.small {
    font-style: normal;
    font-weight: 500;
    font-size: 10px;
    line-height: 12px;
    text-transform: uppercase;
}
</style>