<template>
    <DefaultInput
        name="username"
        label="player nickname"
        placeholder="Choose your player nickname"
        v-model.lazy="value"
        :class="this.formData.username.classes"
        @ionBlur="handleUsernameChange"
        @keyup="handleKeyUp"
    ></DefaultInput>
</template>

<script>
import { ref, watch, defineExpose } from 'vue';
import { mapActions } from 'vuex';
import DefaultInput from '@/components/forms/DefaultInput.vue';

const VerifyFormRef = ref(null);

export default {
  name: 'UsernameInput',
  expose: ['validUsername'],
  components: {
      DefaultInput
  },

  props: {
    modelValue: {
      type: String,
      default: null,
    },
    unique: {
      type: Boolean,
      default: true,
    }
  },

  data() {
    return {
      value: this.modelValue,
      rules: [
        (v) => !!v || 'Required',
      ],
      classes: {
        'error': false
      },
      formData: {
          username: {
          value: '',
          rules: [
              (v) => !!v || 'Nickname is required',
          ],
          error: {
              messages: []
          },
          classes: {
              'error': false
          }
        }
      },
      typingCheck: false,
      errorMessage: ''
    }
  },

  watch: {
    value(value) {
      this.$emit('update:modelValue', value);
    },
  },

  methods: {
    ...mapActions({checkUsername:'users/checkUnique'}),
    handleKeyUp() {
      // Typing check added if onblur check fails
      if(this.typingCheck){
        clearTimeout(this.typingTimer);

        this.typingTimer = setTimeout(() => {
          this.validUsername(this.value);
        }, 500);
      }
    },

    async validUsername(usernameToCheck = false){
      var r = null;
      var errorMessage = "";
      this.formData.username.classes.error = false;
      this.errorMessage = '';

      if(!usernameToCheck || usernameToCheck.length <= 0){
        this.formData.username.classes.error = true;
        return false;
      }

      if(this.checkWhiteSpace(usernameToCheck)) {
        this.formData.username.classes.error = true;
        this.errorMessage = "Nickname cannot have spaces.";
        this.$emit('update-error-message', this.errorMessage);
        return false;
      }

      r = await this.checkUsername({ 
        username: usernameToCheck 
      }).catch((r) => {
          return r.response;
      });

      if(r.status && r.status != 404) {
        if(r.status == 200) {
          if(r.data?.username == "Exists"){
            this.errorMessage = "Nickname is already taken.";
          } else if(r.data?.username == "Invalid") {
            this.errorMessage = "Nickname is invalid.";
          } else {
            this.errorMessage = "There was an issue with this nickname please try another.";
          }
        } else if (r.status == 400) {
          // banned username list
          this.errorMessage = "This nickname is not allowed.";
        } else {
          this.errorMessage = "There was an issue please try again.";
        }

        this.$emit('update-error-message', this.errorMessage);
        this.formData.username.classes.error = true;
        return false;
      }  else {
        // username not found and is valid ( 404 )
        this.formData.username.classes.error = false;
        this.$emit('update-error-message', '');
        return true;
      }
    },

    async handleUsernameChange(event){
      // If blur check fails add typing check
      if(!await this.validUsername(event.target.value)) {
        this.typingCheck = true;
      }
    },

    checkWhiteSpace(s) {
      return s.indexOf(' ') >= 0;
    }
  }
}
</script>