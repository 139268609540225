<template>
    <ion-page>            
        <ion-content class="ion-padding">
            <ion-grid>    
                <ion-row>
                    <ion-col>
                        <img src="/img/banners/banner-feed-2.png"  class="banner-image">
                    </ion-col>
                </ion-row>
            </ion-grid>    
            <ion-grid class="ion-padding-bottom">
            <FeedBubble v-for="item in activity" 
                :id="item.id"
                :title="item.title"
                :subtitle="item.body"
                :icons="{
                    goto: false,
                    msg: false,
                    friendAdd: false,
                }"
                @click="handleActivityClick(item.id)"
            ></FeedBubble>
            </ion-grid>
        </ion-content>
    </ion-page>
</template>

<script>
import { IonNav, IonContent, IonPage, IonTabs, IonGrid, IonCol, IonRow, IonHeader, 
   IonChip, IonIcon } from '@ionic/vue';
import AvatarToolbar from '@/components/ui/AvatarToolbar.vue';
import FeedBubble from '@/components/match/FeedBubble.vue';
import { ref } from 'vue';
import { mapGetters, mapActions } from 'vuex';   

export default {
    name: 'Feed',
    components: {
        IonPage, IonNav, AvatarToolbar, IonContent, IonTabs, IonGrid, IonCol, IonRow, 
        IonHeader, IonChip, IonIcon, FeedBubble, mapGetters, mapActions
    },

    data() {
        return {
            activity: []
        }
    },

    async mounted() {
        const activity = ref(null);

        await this.getActivity;

        this.activity = this.activityData?.data?.results;
    },

    computed: {
        ...mapGetters({
            activityData: 'activity/data',
        }),

        ...mapActions({
            getActivity: 'activity/get',
        })
    },

    methods: {
        handleFriendClick(id){
            this.$router.push(`/profile/${id}`);
        }
    },      
}
</script>

<style scoped>
.btn-header {
    width: auto;
    --background: $ffffff;
}
</style>