<template>
    <ion-grid>
        <ion-row>
            <ion-col class="ion-justify-content-center">
                <img v-if="QRCodeImg" :src="QRCodeImg" :width="width" :height="height" class="qr-code" />
            </ion-col>
        </ion-row>
    </ion-grid>
</template>

<script>
import {
    IonGrid, IonCol, IonRow
} from '@ionic/vue';
import { mapActions } from 'vuex';

export default {
  name: 'QrImage',

  components: {
    IonGrid, IonCol, IonRow
  },
  
  props: {
        width: {
            type: String,
            default: "90%",
        },
        height: {
            type: String,
            default: "auto",
        },
  },

  data() {
    return {
        user: null
    };
  },

  async mounted() {
    this.user = await this.whoami();
  },

  computed: {
    QRCodeImg(){
            if(!this.user){
                return "";
            }

            return `/api/1.0/user/users/${this.user.profile.id}/qr/`;
        },
  },

  methods: {
    ...mapActions({
        whoami: 'auth/whoami'
    }),
  }
}
</script>

<style scoped>
    .qr-code {
        width: 90%;
        height: auto;
    }
</style>