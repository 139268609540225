import api from '@/services/api';

const profiles = {
    namespaced: true,

    state: {
        data: {},
    },

    getters: {
        data(state) {
            return state.data;
        },

        me(state, _, rootState) {
            return state.data[rootState.auth.user?.id];
        },
    },

    actions: {
        async update({ commit, rootState }, params){
            const user = rootState.auth.user;

            if (!user) {
                return;
            }

            const r = await api.patch(`/social/profiles/${user.id}/`, params)
            commit('ADD_PROFILE', r.data);
            return r;
        },

        async whoami({ dispatch, state, rootState }) {
            const user = rootState.auth.user;

            if (!user) {
                return;
            }

            if (state.data[user.id]) {
                return state.data[user.id];
            }

            return await dispatch('get', user.id);
        },

        async get({ commit }, id) {
            const r = await api.get(`/social/profiles/${id}/`);
            commit('ADD_PROFILE', r.data);
            return r.data;
        },

        async all({ commit }) {
            // Get all profiles available to current user.
            // Handle pagination.
            let url = '/social/profiles/';

            while (true) {
                const r = await api.get(url);
                commit('ADD_PROFILE', r.data.results);

                url = r.data.next;
                if (!url)  {
                    break;
                }
            }
        },
    },

    mutations: {
        ADD_PROFILE(state, data) {
            state.data[data.id] = data;
        },

        ADD_PROFILES(state, data) {
            for (const p of data) {
                state.data[p.id] = p;
            }
        },
    },
}

export default profiles;
