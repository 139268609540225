import { createRouter, createWebHistory } from '@ionic/vue-router';
import { isPlatform } from '@ionic/vue';

// Onboard and login pages
import Home from '@/pages/Home.vue';
import Download from '@/pages/Download.vue';
import Login from '@/pages/Login.vue';
import Subscribe from '@/pages/subscription/Subscribe.vue';

// Root home page sections
import MatchesRoot from '@/pages/match/Matches.vue';
import CommunityRoot from '@/pages/community/Community.vue';
import SupportRoot from '@/pages/support/Support.vue';
import InviteRoot from '@/pages/invite/Invite.vue';
import MessageRoot from '@/pages/message/Message.vue';

// Sub page sections
import Settings from '@/pages/support/views/Settings.vue';
import Contact from '@/pages/support/views/Contact.vue';
import Faq from '@/pages/support/views/Faq.vue';
import Messages from '@/pages/message/views/Message.vue';
import MessageDetail from '@/pages/message/views/MessageDetail.vue';
import Feed from '@/pages/community/views/Feed.vue';
import Friends from '@/pages/community/views/Friends.vue'
import Search from '@/pages/community/views/Search.vue';
import Profile from '@/pages/user/views/Profile.vue';
import ProfileEdit from '@/pages/user/views/Edit.vue';
import CheckIn from '@/pages/user/views/CheckIn.vue';
import MatchDetail from '@/pages/match/views/Match.vue';
import MatchSchedule from '@/pages/match/views/Schedule.vue';
import Activity from '@/pages/match/views/Activity.vue';
import MatchHistory from '@/pages/match/views/History.vue';
import InviteFriend from '@/pages/invite/views/Friend.vue';
import PageNotFound from '@/pages/404.vue';
import store from '@/store';

const requiresAuth = [
  '/login', '/login-verify', '/profile', new RegExp('/profile/.*/'), '/profile/edit', '/check-in', '/invite/friend', '/subscribe', 
  '/matches/schedule', '/matches/detail', '/matches/history', '/community/feed', '/community/friends', '/community/search', 
  '/support/settings', '/support/contact', '/support/faq', '/messages', new RegExp('/messages.*/')
];

const routes = [
  { path: '/', component: Home },
  { path: '/home', component: Home },
  { path: '/download', component: Download },
  
  // Registration and Onboarding urls
  { path: '/login', component: Login },

  // Start Private urls

  // Subscription urls
  { path: '/subscribe', component: Subscribe },

  // User URLS
  { path: '/profile/:id?', component: Profile },
  { path: '/profile/edit', component: ProfileEdit }, 
  { path: '/check-in', component: CheckIn }, 

  // Match URLS
  {
    path: '/matches',
    component: MatchesRoot,
    children: [
      {
        path: '', 
        redirect: '/matches/schedule',
      },
      {
        path: 'schedule', 
        component: MatchSchedule,
      },
      {
        path: 'detail', 
        component: MatchDetail,
      },
      {
        path: 'activity', 
        component: Activity,
      },
      {
        path: 'history', 
        component: MatchHistory,
      }
    ]
  },

  // Invite URLs
  {
    path: '/invite',
    component: InviteRoot,
    children: [
      {
        path: '/', redirect: '/friend',
      },
      {
        path: 'friend', component: InviteFriend,
      }
    ]
  },
  // End Private URLS

  // Support URLS
  {
    path: '/support',
    component: SupportRoot,
    children: [
      {
        path: 'settings',
        component: Settings,
      },      
      {
        path: 'contact',
        component: Contact,
      },
      {
        path: 'faq',
        component: Faq,
      },
    ]
  },

  // Community URLs
  {
    path: '/community',
    component: CommunityRoot,
    children: [
      {
        path: '',
        redirect: '/community/feed',
      },
      {
        path: 'feed', 
        component: Feed,
      },
      {
        path: 'friends',
        component: Friends,
      },
      {
        path: 'search', 
        component: Search,
      },
    ],
  },

  // Message URLs
  {
    path: '/messages',
    component: MessageRoot,
    children: [
      {
        path: '',
        component: Messages,
      },
      {
        path: ':id',
        component: MessageDetail,
      }
    ]
  },
  
  // 404 catchall show download PWA homepage
  { path: '/:pathMatch(.*)*', component: PageNotFound },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

router.beforeEach(async (to, from) => {
  // If user is using a desktop redirect to QR download page unless on /download
  if (isPlatform('desktop') && to.path !== '/download') {
    return '/download';
  }

  if(!requiresAuth.some(path => {
    if(typeof path === 'string' ? path === to.path : path instanceof RegExp && path.test(to.path)){
      return true; // if path contains regular expression use regex to search the path or if path is a string then compare it to current path
    }
  })){
    // If the regexpression or string search do not find a match return, if not then continue to whoami
    return;
  }

  // If a user is not logged in a 403 is returned and must be caught
  const user = await store.dispatch('auth/whoami').catch((err) => {});

  if (!user && to.path !== '/login') { // Stops infinite redirects to Login
    return '/login';
  } else if (user && to.path == '/login') { // If user is logged and on login page return them to matches/schedule
    return '/matches/schedule'
  }
});

export default router;
