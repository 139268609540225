<template>
    <ion-toolbar class="ion-text-center ion-padding-horizontal">
        <ion-buttons slot="start">
            <ion-back-button 
            text="" 
            :icon="arrowBack"
            :defaultHref="defaultHref" 
            class="ion-float-left"></ion-back-button>
        </ion-buttons>
        <ion-buttons slot="end" class="ion-justify-content-start">
            <ion-button
                class="btn-header"
                fill="clear"
                @click="goToProfileMessages(id)"
                v-if="showMessageBtn"
            >
                <ion-icon src="/img/icons/ui/msg-chat-circle.svg" class="toolbar-icon badge-button" slot="icon-only"></ion-icon>
            </ion-button>
            <ion-button
                class="btn-header"
                fill="clear"
                v-if="showEditBtn"
                @click="goToProfileEdit()"
            >
                <ion-icon src="/img/icons/ui/edit-icon.svg" class="toolbar-icon badge-button" slot="icon-only"></ion-icon>
            </ion-button>
        </ion-buttons>
    </ion-toolbar>
</template>

<script>
import { IonToolbar, IonButton, IonButtons, IonBackButton, IonIcon } from '@ionic/vue';
import { arrowBack } from 'ionicons/icons';
import Avatar from '@/components/Avatar.vue';

export default {
  name: 'ProfileToolbar',

  components: {
    IonToolbar, IonButton, IonButtons, IonBackButton, IonIcon, arrowBack, Avatar
  },
  
  data() {
    return {
        arrowBack: null
    }
},
    
  props: {
    id: {
        type: String,
        default: null,
    },
    defaultHref: {
        type: String,
        default: null,
    },
    showEditBtn: {
        type: Boolean,
        default: false,
    },
    showMessageBtn: {
        type: Boolean,
        default: false,
    },
  },

  methods: {
    goToProfileMessages(id) {
        this.$router.push(`/messages/${id}`);
    },
    
    goToProfileEdit() {
        this.$router.push(`/profile/edit`);
    },
  }
}
</script>

<style scoped>
    ion-button {
        width: auto;
    }

    .btn-header {
        --background: #044784;
        --border-radius: 100px !important;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        gap: 4px;
        min-width: 50px;
        min-height: 50px;
    }
</style>