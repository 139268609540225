<template>
    <ion-page>
        <ion-tabs>
            <ion-header collapse="fade" class="ion-no-border ion-margin-top">
                <AvatarToolbar></AvatarToolbar>
                <CommunityChip                    
                    :active=activeChip
                ></CommunityChip>
            </ion-header>     
            <ion-router-outlet></ion-router-outlet>
            <FooterTabBar active="/community/" id="ionTabFooter"></FooterTabBar>
        </ion-tabs>
    </ion-page>
</template>

<script>
import { IonRouterOutlet, IonNav, IonHeader, IonPage, IonTabs  } from '@ionic/vue';
import AvatarToolbar from '@/components/ui/AvatarToolbar.vue';
import CommunityChip from '@/components/ui/nav/CommunityChip.vue';
import FooterTabBar from '@/components/ui/FooterTabBar.vue';

export default {
    name: 'Community',
    components: {
        IonRouterOutlet, IonNav, IonHeader, IonPage, IonTabs, AvatarToolbar, CommunityChip, FooterTabBar
    },

    data() {
        return {
            activeChip: this.$route.meta.activeChip ? this.$route.meta.activeChip : this.$route.path
        }
    },
    
    beforeRouteUpdate(to, from) {
        this.activeChip = to.path;
    }
}
</script>

<style scoped>
ion-button {
    width: auto;
}

.btn-header {
    width: auto;
    --background: $ffffff;
}

.btn-add-schedule {
    --background: #044784;
    --color: #fff;
    height: 32px;
    margin-left: auto;
}

h2 {
    margin-top: 0px;
    line-height: 30px;
}
</style>