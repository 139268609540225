<template>
  <ion-page>
    <div v-if="state === 'login'" class="dink247-login-container">        
      <LoginForm
        @success="onCodeSent"
        @notfound="onNotFound"
      ></LoginForm>
    </div> 
    <div v-else-if="state === 'verify'" class="dink247-verify-container">
      <LoginVerify
        :email="form.email"
        :mobile="form.mobile"
        :nonce="form.nonce"
        :initial="form.code"
        :count="6"
        @login="onLogin"
        @back="reset"
      ></LoginVerify>
    </div>
    <div v-else-if="state === 'register'" class="dink247-verify-container">
      <MoreDetails
        :email="form.email"
        :mobile="form.mobile"
        @register="onRegister"
        @back="reset"
      ></MoreDetails>
    </div>
    <div
      v-if="errorMessage"
      class="error ion-margin-horizontal"
    >{{ errorMessage }}</div>
  </ion-page>
</template>

<script>
import { IonContent, IonPage, IonButton, IonButtons } from '@ionic/vue';
import Header from '@/components/Header.vue';
import Footer from '@/components/FooterButton.vue';
import LoginForm from '@/components/login/LoginForm.vue';
import LoginVerify from '@/components/login/VerifyForm.vue';
import MoreDetails from '@/components/onboarding/MoreDetails.vue';

export default {
  name: 'Login',
  components: {
    IonContent, IonPage, IonButton, IonButtons, Header, Footer, LoginForm, LoginVerify, MoreDetails
  },

  beforeRouteLeave(to, from) {
      // Reset page
      this.reset();
  },

  data() {
    const params = new URLSearchParams(window.location.search);
    const verificationCode = params.get("code");
    history.replaceState(history.state, '', '/login');

    return {
      form: {
        email: null,
        mobile: null,
        nonce: null,
        code: verificationCode,
      },
      userNotFound: false,
      errorMessage: null,
    };
  },

  computed: {
    state() {
      if (this.form.nonce) {
        return 'verify';
      }
      if (this.userNotFound) {
        return 'register';
      } else {
        return 'login';
      }
    },
  },

  methods: {
    onCodeSent({ nonce }) {
      this.form.nonce = nonce;
    },

    onNotFound({ email, mobile }) {
      this.form.email = email;
      this.form.mobile = mobile;
      this.userNotFound = true;
    },

    onLogin() {
      this.$router.push('/matches/schedule');
    },
  
    onRegister({ nonce }) {
      this.form.nonce = nonce;
    },

    reset() {
      this.form.nonce = null;
      this.form.email = null;
      this.form.mobile = null;
      this.userNotFound = false;
      this.errorMessage = null;
    },
  }
}
</script>

<style scoped>
.dink247-login-container {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}

.dink247-verify-container {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}

.dink247-login-content {
  --background: none; /* to override ion-content background this must be done or image has white bars */
}
</style>