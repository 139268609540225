<template>
    <ion-page>
        <ion-tabs>
            <ion-header collapse="fade" class="ion-no-border ion-margin-top">
                <AvatarToolbar></AvatarToolbar>
                <MatchChip 
                    :active=activeChip
                ></MatchChip>
            </ion-header>
            <ion-router-outlet></ion-router-outlet>
            <FooterTabBar active="/matches/"></FooterTabBar>
        </ion-tabs>
    </ion-page>
</template>

<script>
import { IonRouterOutlet, IonNav, IonHeader, IonPage, IonTabs } from '@ionic/vue';
import AvatarToolbar from '@/components/ui/AvatarToolbar.vue';
import MatchChip from '@/components/ui/nav/MatchChip.vue';
import FooterTabBar from '@/components/ui/FooterTabBar.vue';

export default {
    name: 'My Matches',
    components: {
        IonRouterOutlet, IonNav, IonHeader, IonPage, IonTabs, AvatarToolbar, MatchChip, FooterTabBar
    },

    data() {
        return {
            activeChip: this.$route.meta.activeChip ? this.$route.meta.activeChip : this.$route.path
        }
    },

    beforeRouteUpdate(to, from) {
        this.activeChip = to.path;
    }
}
</script>