import SocketIO from 'socket.io-client';
import VueSocketIO from 'vue-3-socket.io';
import store from '@/store';
import { API_BASE_URL } from '@/config';

const API_HOST_NAME = new URL(API_BASE_URL).hostname;

const socket = SocketIO(`wss://${API_HOST_NAME}`, {
    path: '/socket.io',
    autoConnect: false,
    transports: ['websocket'],
});

socket.on('connect', async () => {
  const token = store.getters['auth/token'];
  if (!token) {
    return;
  }

  socket.emit('authenticate', { token });
});

export default new VueSocketIO({
  debug: true,
  connection: socket,
  vuex: {
    store,
    actionPrefix: 'socket_'
  }
});
