<template>
    <div class="ion-margin-top chip-nav">
        <ion-chip :class="{ active: active === '/community/feed' }" @click="goToNav('/community/feed')">Feed</ion-chip>
        <ion-chip :class="{ active: active === '/community/friends' }" @click="goToNav('/community/friends')">Friends</ion-chip>
        <ion-chip :class="{ active: active === '/community/search' }" @click="goToNav('/community/search')">Search</ion-chip>
    </div>
</template>

<script>
import { IonChip } from '@ionic/vue';

export default {
  name: 'NavCommunityChip',

  components: {
    IonChip
  },
  
  props: {
      active: {
          type: String,
          default: '',
      }
  },

  methods: {
    goToNav(page){
        this.$router.replace(page);
    }
  },
}
</script>