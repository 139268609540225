<template>
    <ion-page>
        <Header
            logo="white"
            type="logo"
            defaultHref="/login"
            @backButtonCallback="$emit('back')"
        ></Header>
        <ion-content class="ion-padding" scroll-y="false">
            <h2 class="ion-text-center">Enter 6 Digit Verification Code</h2>
            <ion-grid>
                <ion-row class="ion-justify-content-center ion-padding-top">
                    <ion-col
                        v-for="(input, index) in inputs"
                        :key="index"
                        class="ion-justify-content-center"
                    >
                        <ion-input
                            v-model="inputs[index].value"
                            :name="`verifyCode${index + 1}`"
                            type="tel"
                            inputmode="numeric"
                            :tabindex="index + 1"
                            :maxlength="index == 0 ? count.toString() : '1'"
                            label-placement="stacked"
                            fill="solid"
                            shape="round"
                            class="native-input sc-ion-input-md ion-text-center dink-input"
                            :class="inputs[index].classes"
                            :autocomplete="index == 0 ? 'one-time-code' : null"
                            @keypress="onInput"
                            @keyup="onInput"
                            @paste="onPaste"
                        />
                    </ion-col>
                </ion-row>
                <ion-row>
                    <ion-col>
                        <span class="resend-button" :onClick="handleResendCode">Resend code</span>
                    </ion-col>
                </ion-row>
                <ion-row v-if="errorMessage">
                    <ion-col>
                        <div
                        class="error ion-margin-top"
                    >{{ errorMessage }}</div>
                    </ion-col>
                </ion-row>
                <ion-toast 
                    :is-open="toastOpen" 
                    :message="successMessage" 
                    :duration="2000"
                    position="bottom"
                    :icon="checkmarkCircleOutline"
                    position-anchor="footer"
                    class="success-toast"
                    @didDismiss="setToast(false)"
                ></ion-toast>
        </ion-grid>
    </ion-content>
    <Footer
        id="footer"
        buttonText="Verify"
        @click="onVerify"
    ></Footer>
</ion-page>
</template>

<script>
import { IonPage, IonContent, IonCol, IonGrid, IonRow, IonInput, IonToast } from '@ionic/vue';
import { checkmarkCircleOutline } from 'ionicons/icons';
import FormVerification from '@/composables/verification.js'
import { mapActions } from 'vuex';
import Header from '@/components/Header.vue';
import Footer from '@/components/FooterButton.vue';

const { displayErrorMessage, validateForm } = FormVerification();

export default {
    name: 'VerifyForm',
    expose: ['onVerify'],
    components: {
        IonPage, Header, Footer, IonContent, IonCol, IonGrid, IonRow, IonInput, IonToast
    },

    props: {
        count: {
            type: Number,
            default: 6,
            required: true
        },
        mobile: {
            type: String,
            default: ''
        },
        email: {
            type: String,
            default: ''
        },
        nonce:  {
            type: String,
            default: null,
        },
        initial: {
            type: String,
            default: null,
        },
    },

    data() {
        return {
            inputs: [],
            otp: null,
            errorMessage: null,
            successMessage: null,
            toastOpen: false,
            checkmarkCircleOutline
        };
    },

    created() {
        // Create input dynamically based on count
        var i = 0;
        var initial = this.initial || '';
        this.inputs = Array(this.count).fill().map(() => ({
            value: initial[i++] || '',
            rules: [
                (v) => !!v || 'Complete verification code is required',
            ], 
            error: {
                messages: []
            },
            classes: {
                'error': false
            }
        }));
        setTimeout(this.focusInput.bind(this), 0);
    },

    computed: {
        code() {
            let code = '';
            for (let i = 0; i < this.inputs.length; i++) {
                code += this.inputs[i].value;
            }
            return code;
        }
    },

    mounted() {
        this.initOtp(); // Call the method on component mount
        this.maybeVerify();
    },

    emits:["update:modelValue", "login", "back"],

    methods: {
        ...mapActions({
            verifyCode: 'auth/verifyCode',
            resendCode: 'auth/login',
        }),

        onInput(event) {
            const c = event.key;
            const pos = event.target.tabIndex - 1;

            if (c === 'Backspace' || c === 'Delete') {
                if (this.inputs[pos].value) {
                    this.inputs[pos].value = '';
                } else {
                    this.focusInput(-1);
                }
                return;
            } else if (c === 'Enter') {
                this.onVerify();
                return;
            }

            const code = c.charCodeAt(0);
            if (code < 48 || code > 57) {
                event.preventDefault();
                return false;
            }

            this.$emit("update:modelValue", this.code);
            this.focusInput();
            this.maybeVerify();
        },

        resetCode() {
            for (var i = 0; i < this.count; i++) {
                this.inputs[i].value = "";
            }
        },

        maybeVerify() {
            if (this.code.length == this.count) {
                this.onVerify();
            }
        },

        focusInput(dir=null) {
            let pos = 0;
            for (; pos < this.count; pos++) {
                if (!this.inputs[pos].value) {
                    break;
                }
            }
            pos++;
            if (dir !== null) {
                pos += dir;
            }
            const element = document.querySelector(`input[tabIndex="${pos}"]`);
            if (!element) {
                return;
            }
            element.focus();
        },

        async initOtp() {
            if ('OTPCredential' in window) {
                navigator.credentials.get({
                    otp: { transport:['sms'] }
                }).then(otp => {
                    this.pasteInput(otp.code);
                });
            } 
        },

        handleOtpChange(event) {
            this.pasteInput(event.target.value);
        },

        onPaste(event) {
            const pastedText = (event.clipboardData || window.clipboardData)?.getData('text');
            this.pasteInput(pastedText);
            event.preventDefault();
            return false;
        },

        pasteInput(pastedText){
            if (!pastedText) {
                return;
            }

            // Loop though paste code and add it to inputs
            for (var i = 0; i < pastedText.length; i++) {
                if(!isNaN(pastedText[i])){
                    this.inputs[i].value = pastedText[i];          
                }
            }
            this.focusInput();
            this.maybeVerify();
        },

        async handleResendCode(){
            try {
                const r = await this.resendCode({
                    mobile: this.mobile,
                    email: this.email,
                });

                this.successMessage = 'Code sent';
                this.setToast(true);

            } catch (e) {
                this.errorMessage = `Error resending code please try again`;
                return;
            }
        },

        async onVerify() {
            if (this.code.length < this.count) {
                return;
            }

            try {
                const r = await this.verifyCode({ nonce: this.nonce, code: this.code });

                this.resetCode();
                this.$emit('login');

            } catch(e) {
                this.resetCode();
                let errorMessage = e.response?.data?.non_field_errors;
                if (errorMessage) {
                    errorMessage = errorMessage[0];
                }

                this.errorMessage = errorMessage;

            }
        },

        setToast(state) {
            this.toastOpen = state;
        },
    },
}
</script>

<style scoped>
ion-input.dink-input {
    --border-radius: 12px;
    font-weight: bold;
    font-size: 1.1em;
    min-width: 37px;
    max-width: 70px;
}

ion-toast {
    --background: #333333;
    --box-shadow: 3px 3px 10px 0 rgba(0, 0, 0, 0.2);
    --height: 80px;
  }

.success-toast {
    color: #ffffff;
}

.resend-button {
    margin-left: 5px;
    font-weight: 500;
    font-size: 0.8em;
}
</style>