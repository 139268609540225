<template>
    <ion-list :inset="true" class="message-list ion-no-margin">
        <!--TODO Connect to backend API and remove placeholding code-->
    </ion-list>  
</template>

<script>
import { IonGrid, IonCol, IonRow, IonButton, IonButtons, IonLabel, IonIcon, IonList, IonItem } from '@ionic/vue';
import MessageItem from '@/components/message/Message.vue';
import { ref } from 'vue';
import { mapGetters, mapActions, useStore } from 'vuex';   


export default {
  name: 'Community Chat',
  components: {
    IonGrid, IonCol, IonRow, IonButton, IonButtons, IonLabel, IonIcon, IonList, IonItem, 
    MessageItem, mapGetters, mapActions, useStore
  },

  data() {
    return {
         messages: []
    }
  },

  async mounted() {
        const messages = ref(null);

        await this.getMessages;

        this.messages = this.messagesData?.data?.result;

        return { messages } 
    },

    computed: {
        ...mapGetters({
            messsagesData: 'messages/data',
        }),

        ...mapActions({
            getMessages: 'messages/get',
        })
    },

    methods: {
        handleMessageClick(id){
            this.$router.push(`/messages/detail/${id}`);
        }
    }
}
</script>

<style scoped>
    .message-list {
        margin-top: 0px;
        margin-left: 0px;
        margin-inline-start: 0px;
        -webkit-margin-end: 0px;
    }

    .item-icon {
        font-size: 24px;
    }

    ion-button {
        --height: 32px;
    }

    .btn-check-in {
        width: auto;
        height: 50px;
    }

    ion-button[shape=circle] {
        --border-radius: 50%;
        width: 50px;
        height: 50px;
    }
</style>