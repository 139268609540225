<template>
    <ion-grid class="searchbar-container ion-no-padding">
        <ion-row>
            <ion-col class="ion-align-items-center">
                <ion-searchbar
                    :name="name"
                    label-placement="stacked" 
                    fill="solid"
                    :placeholder="placeholder"
                    v-model="modelValue"
                    show-clear-button="focus"
                    enterkeyhint="search"
                    inputmode="search"
                    @input="onInput"
                    @keyup.enter="submitSearch"
                    class="native-input ion-text-start ion-no-padding">
                </ion-searchbar>
                <ion-button
                    class="btn-search"
                    fill="clear"
                    @click="submitSearch"
                >
                    <ion-icon src="/img/icons/ui/search-icon-white.svg" slot="icon-only"></ion-icon>
                </ion-button>
            </ion-col>
        </ion-row>
    </ion-grid>
</template>

<script>
import { IonCol, IonGrid, IonRow, IonInput, IonSearchbar, IonButton, IonIcon } from '@ionic/vue';

export default {
    name: 'SearchInput',

    components: {
        IonCol, IonGrid, IonRow, IonInput, IonSearchbar, IonButton, IonIcon
    },

    props: {
        name: {
            type: String,
            default: '',
            required: true
        },
        placeholder: {
            type: String,
            default: '',
            required: false
        },
        value: {
            type: String,
            default: ''
        },
        type: {
            type: String,
            default: '',
            required: false
        }
    },

    data() {
        return {
            modelValue: this.modelValue
        };
    },

    emits:["searchCallback", "update:modelValue"],
    methods: {
        onInput(e) {
            this.$emit("update:modelValue", e.target.value);
        },

        submitSearch(){
            this.$emit('searchCallback');
        },
    },
    
    created() {
        this.modelValue = this.value;
    }
}
</script>

<style scoped>
label.small {
    font-style: normal;
    font-weight: 500;
    font-size: 10px;
    line-height: 12px;
    text-transform: uppercase;
}

.searchbar-container {
    padding-left: 6px;
}

ion-searchbar {
    border-radius: 12px;
    background: #f2f2f2;
    --background: #f2f2f2;
    --padding-start: 16px;
    --padding-end: 16px;
    min-height: 56px;
    border: 1px solid var(--background);
    --highlight-color-focused: none;
    --webkit-box-shadow: none;
    --box-shadow: none;
    display: flex;
    align-items: center;
}

ion-button {
    width: 50px;
    color: #fff;
    font-size: 12px;
    height: 56px;
    margin: 0px 4px 0px 2px;
    --border-radius: 12px;
}

.search-icon {
    width: 24px;
    height: 24px;
}
</style>