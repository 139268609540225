<template>
    <div class="ion-margin-top chip-nav">
        <ion-chip :class="{ active: active === '/support/settings' }" @click="goToNav('/support/settings')">Settings</ion-chip>
        <ion-chip :class="{ active: active === '/support/faq' }" @click="goToNav('/support/faq')">FAQ</ion-chip>
        <ion-chip :class="{ active: active === '/support/contact' }" @click="goToNav('/support/contact')">Support</ion-chip>
    </div>
</template>

<script>
import { IonChip } from '@ionic/vue';

export default {
  name: 'NavSupportChip',

  components: {
    IonChip
  },

  props: {
    active: {
      type: String,
      default: null,
    }
  },

  methods: {
    goToNav(page){
        this.$router.replace(page);
    }
  },

}
</script>