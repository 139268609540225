<template>
    <ion-avatar 
      v-if="id"
      :style="{width, height}"
      @click="goToProfile()"
    >
      <img 
        :alt="alt" 
        :src="avatar"
        :style="{width, height}"
      />
    </ion-avatar>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import { IonAvatar } from '@ionic/vue';
import { API_BASE_URL } from '@/config';

export default {
  name: 'Avatar',
  components: {
      IonAvatar
  },

  props: {
    profile: {
      type: Object,
      default: null,
    },
    user: {
      type: Object,
      default: null,
    },
    clickable: {
      type: Boolean,
      default: true,
    },
    width:  {
      type: String,
      default: '50px',
    },
    height:  {
      type: String,
      default: '50px',
    },
  },

  mounted() {
    // NOTE: Only need the user to render current user.
    if (this.profile || this.user) {
      return;
    }

    this.getUser();
  },

  computed: {
    ...mapGetters({
      'me': 'auth/me',
    }),

    id() {
      return this.profile?.id || this.user?.id || this.me?.id;
    },

    avatar() {
      if (!this.id) {
        return;
      }

      const url = new URL(`/api/1.0/social/profiles/${this.id}/avatar/`, API_BASE_URL);
      return url.toString();
    },

    alt() {
      return this.profile?.user?.username || this.user?.username || this.me?.username;
    },
  },

  methods: {
    ...mapActions({
      getUser: 'auth/whoami'
    }),

    goToProfile() {
      if (!this.clickable || !this.id) {
        return;
      }

      this.$router.push(`/profile/${this.id}`);
    },
  },
}
</script>

<style scoped>
  ion-avatar {
    --border-radius: 100px;
  }
</style>