import { createStore } from 'vuex';
import auth from '@/store/auth';
import users from '@/store/users';
import messages from '@/store/messages';
import friends from '@/store/friends';
import profiles from '@/store/profiles';
import payment from '@/store/payment';
import activity from '@/store/activity';
import app from '@/store/app';

const store = createStore({
  modules: {
    auth,
    users,
    messages,
    friends,
    profiles,
    payment,
    activity,
    app
  }
});

export default store;
