<template>
    <div class="ion-margin-top chip-nav">
        <ion-chip :class="{ active: active === '/matches/schedule' }" @click="goToNav('/matches/schedule')">Bookings</ion-chip>
        <ion-chip :class="{ active: active === '/matches/activity' }" @click="goToNav('/matches/activity')">Activity</ion-chip>
        <ion-chip :class="{ active: active === '/matches/history' }" @click="goToNav('/matches/history')">History</ion-chip>
    </div>
</template>

<script>
import { IonChip } from '@ionic/vue';

export default {
  name: 'NavMatchChip',

  components: {
    IonChip
  },

  props: {
      active: {
          type: String,
          default: null,
      }
  },

  methods: {
    goToNav(page){
        this.$router.replace(page);
    }
  },
}
</script>