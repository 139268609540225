<template>
    <ion-tab-bar slot="bottom">
        <ion-tab-button tab="matches" @click="goToNav('/matches/schedule')" :class="{ active: active === '/matches/' }">
            <ion-icon src="/img/icons/ui/home-icon.svg" class="tab-icon"></ion-icon>
            <ion-label>Home</ion-label>
        </ion-tab-button>

        <ion-tab-button tab="checkin" @click="goToNav('/check-in')" :class="{ active: active === '/check-in/' }">
            <ion-icon src="/img/icons/ui/calendar-tick-icon.svg" class="tab-icon"></ion-icon>
            <ion-label>Check in</ion-label>
        </ion-tab-button>

        <ion-tab-button tab="community" @click="goToNav('/community/feed')" :class="{ active: active === '/community/' }">
            <ion-icon src="/img/icons/ui/24-support-icon.svg" class="tab-icon"></ion-icon>
            <ion-label>Community</ion-label>
        </ion-tab-button>
    </ion-tab-bar>
</template>

<script>
import { IonTabBar, IonTabButton, IonIcon, IonLabel } from '@ionic/vue';

export default {
  name: 'FooterTabBar',

  components: {
    IonTabBar, IonTabButton, IonIcon, IonLabel
  },
  
  methods: {
    async goToNav(page){
        if(page != this.active){ // disable clicking on active nav tab
            this.$router.replace(page);
        }
    }
  },

  props: {
      active: {
          type: String,
          default: '',
      }
  }
}
</script>

<style scoped>
    ion-tab-bar {
        height: 80px;
    }

    ion-tab-button {
        color:#8F99A3;
    }

    ion-tab-button.active {
        color:#044784;
    }

    ion-label {
        margin-top: 3px;
    }

    .tab-icon, .item-icon {
        font-size: 24px;
    }
</style>