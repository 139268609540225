<template>
    <div class="toolbar-dink-dollar-container">
        <div :class="classes">Balance:</div>
        <div class="toolbar-dink-dollar" :class="{ zero: isZero }">{{ formatPrice(balance) }}</div>
    </div>
</template>

<script>

export default {
  name: 'DinkCreditBalance',

  props: {
      classes: {
        type: Object,
        default: null,
      }
  },

  data() {
    return {
        // TODO connect to backend to pull balance.
        balance: 0
    };
  },

  computed: {
    isZero() {
        return !(this.balance > 0);
    },
  },

  methods: {
    formatPrice(price){
        return `$${(price / 100).toFixed(2)}`;
    },
  }
}
</script>

<style scoped>
    .toolbar-dink-dollar-container {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;
        margin-left: 15px;
    }

    .toolbar-dink-dollar-container .bold{
        font-weight: 500;
    }

    .toolbar-dink-dollar {
        color: green;
        display: flex;  
    }

    .toolbar-dink-dollar.zero {
        color: red; 
    }
</style>