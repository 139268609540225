
<template>
    <ion-page>  
      <ion-header collapse="fade" class="ion-no-border ion-margin-top">
        <AvatarToolbar></AvatarToolbar>
      </ion-header>    
      <ion-content class="ion-padding" scroll-y="false">
        <h2 class="ion-text-center">Invite a Friend</h2>
        <form ref="myForm">
            <ion-grid>
                <ion-row>
                  <ion-col>
                    <img src="/img/banners/banner-invite.png" class="banner-image">
                  </ion-col>
                </ion-row>
                <ion-row>
                    <ion-col>
                        <DefaultInput
                            name="inviteName"
                            label="Friend's Name"
                            placeholder="Your friend's name"
                            v-model="formData.inviteName.value"
                            :class="formData.inviteName.classes"
                            ref="friendComponent"
                        ></DefaultInput>
                    </ion-col>
                </ion-row>
                <ion-row>
                    <ion-col>
                        <DefaultInput
                            id="invitePhone"
                            name="invitePhone"
                            label="Friend's Phone"
                            placeholder="Your friend's phone number"
                            v-model="formData.invitePhone.value"
                            class="ion-margin-bottom"
                            :class="formData.invitePhone.classes"
                        ></DefaultInput>
                    </ion-col>
                </ion-row>
                <ion-row>
                  <ion-col>
                      <div
                      v-if="errorMessage"
                      class="error ion-margin-bottom ion-margin-horizontal"
                    >{{ errorMessage }}</div>
                    <div
                      v-if="successMessage"
                      class="success ion-margin-bottom ion-margin-horizontal"
                    >{{ successMessage }}</div>
                    <ion-toast 
                      :is-open="toastOpen" 
                      :message="successMessage" 
                      :duration="2000"
                      position="bottom"
                      :icon="toastIcon"
                      position-anchor="footer"
                      class="success-toast"
                      @didDismiss="setToast(false)"
                    ><ion-img slot="image" :src="imageUrl" /></ion-toast>
                  </ion-col>
                </ion-row>  
              </ion-grid>
          </form>
      </ion-content>
      <Footer
          class="offset-tabbar"
          id="footer"
          buttonText="Invite Friend"
          @click="submitInviteFriend"
      ></Footer>
    </ion-page>
</template>

<script>
import { IonPage, IonContent, IonCol, IonGrid, IonRow, IonHeader, IonTabs, IonToast, IonIcon, 
  IonImg  } from '@ionic/vue';
import { peopleCircleOutline, cardOutline  } from 'ionicons/icons';
import { useRouter } from 'vue-router';
import Header from '@/components/Header.vue';
import AvatarToolbar from '@/components/ui/AvatarToolbar.vue';
import Footer from '@/components/FooterButton.vue';
import DefaultInput from '@/components/forms/DefaultInput.vue';
import FormVerification from '@/composables/verification.js'
import { mapActions } from 'vuex';
import { ref } from 'vue';

const { displayErrorMessage, validateForm, isValidPhone, cleanPhone } = FormVerification();

export default {
  name: 'Invite a Friend',
  components: {
    IonPage, IonContent, Header, AvatarToolbar, Footer, IonCol, IonGrid, IonRow, IonHeader, 
    IonTabs, IonToast, IonIcon, IonImg, DefaultInput, ref, FormVerification
  },

  data() {
    return {
      formData: {
        inviteName: {
          value: '',
          rules: [
            (v) => !!v || 'Friends name is a required field'
          ],
          error: {
            messages: []
          },
          classes: {
            'error': false
          }
        },
        invitePhone: {
          value: '',
          rules: [
            (v) => !!v || 'Friend\'s phone number is a required field',
            (v) => isValidPhone(v) || 'A valid phone number must be used'
          ],
          error: {
            messages: []
          },
          classes: {
            'error': false
          }
        }
      },
      errorMessage: null,
      successMessage: null,
      toastOpen: false,
      imageUrl: "/img/icons/icon-248x248.png",
      toastIcon: peopleCircleOutline
    }
  },
  
  methods: {
    ...mapActions({inviteFriend:'users/inviteFriend'}),
    async submitInviteFriend() {
      this.errorMessage = '';
      this.successMessage = '';

      if(!validateForm(this.formData)){
        this.errorMessage = displayErrorMessage(this.formData);
        return false;
      }

      var mobile = this.formatPhone(cleanPhone(this.formData.invitePhone.value));
      
      var options = {
        name: this.formData.inviteName.value,
        mobile: mobile
      }

      await this.inviteFriend(options).then(r => {
          if(r.status && r.status == 201) {
            this.successMessage = `Friend invite has been sent.`;
            this.setToast(true);
            this.resetForm();
            return true;
          } else {
            this.errorMessage = `Error inviting a friend please try again`;
            return false;
          }
      }).catch((r) => {
          this.errorMessage = `Error inviting a friend please try again`;
      });
    },

    resetForm(){
      this.formData.inviteName.value = "";
      this.formData.invitePhone.value = "";
      this.$refs.myForm.reset()
    },

    formatPhone(value) {
      let formatted = '';
      formatted = '+1' + value; 

      return formatted;
    },

    setToast(state, icon = peopleCircleOutline) {
      this.toastIcon = icon;
      this.toastOpen = state;
    }
  }
}
</script>

<style scoped>
  ion-toast {
    --background: #333333;
    --box-shadow: 3px 3px 10px 0 rgba(0, 0, 0, 0.2);
    --height: 80px;
  }
  .success-toast {
    color: #ffffff;
  }
  .offset-tabbar {
    margin-top: -160px;
  }
</style>