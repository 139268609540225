<template>
    <ion-grid>
        <ion-row class="ion-margin-bottom">
            <ion-col>
                <avatar
                    :profile="profile"
                    :clickable="false"
                    width="80px"
                    height="80px"
                ></avatar>
                <DinkCreditBalance 
                    :classes="{bold: true}"
                ></DinkCreditBalance>
            </ion-col>
        </ion-row>
        <ion-row>
            <ion-col>
                <span
                    v-if="profile"
                    class="profile-name"
                >@{{ profile.user?.username }}</span>
                <ion-skeleton-text
                    v-else
                    animated
                    style="height: 28px; width: 100px;"
                ></ion-skeleton-text>
                <div class="feed-badges">
                    <!-- TODO add badges -->
                </div>
            </ion-col>
        </ion-row>
        <ion-row class="ion-margin-bottom">
            <ion-col class="profile-bio">
                {{ profile?.bio }}
            </ion-col>
        </ion-row>
        <ion-row>
            <ion-col>
                <ion-list class="profile-rating">
                    <ion-item>
                        <ion-label>Dink Rank</ion-label>
                        <ion-label class="ion-text-right">
                            <template
                                v-if="profile"
                            >{{ rating }}</template>
                            <ion-skeleton-text
                                v-else
                                animated
                                style="height: 16px; min-width: 100%;"
                            ></ion-skeleton-text>
                        </ion-label>
                    </ion-item>
                    <ion-item>
                        <ion-label>Games Played</ion-label>
                        <ion-label class="ion-text-right">
                            <template
                                v-if="profile"
                            >{{ games }}</template>
                            <ion-skeleton-text
                                v-else
                                animated
                                style="height: 16px; min-width: 100%;"
                            ></ion-skeleton-text>
                        </ion-label>
                    </ion-item>
                    <ion-item>
                        <ion-label>Home Court</ion-label>
                        <ion-label class="ion-text-right">
                            <template
                                v-if="profile"
                            >{{ facility }}</template>
                            <ion-skeleton-text
                                v-else
                                animated
                                style="height: 16px; min-width: 100%;"
                            ></ion-skeleton-text>
                        </ion-label>
                    </ion-item>
                </ion-list>
            </ion-col>
        </ion-row>
    </ion-grid>
</template>

<script>
import {
    IonGrid, IonCol, IonRow, IonList, IonItem, IonIcon, IonLabel, IonSkeletonText, IonThumbnail
} from '@ionic/vue';
import Avatar from '@/components/Avatar.vue';
import DinkCreditBalance from '@/components/ui/DinkCreditBalance.vue'

export default {
  name: 'Profile',

  components: {
    IonGrid, IonCol, IonRow, IonList, IonItem, IonIcon, IonLabel, IonSkeletonText, IonThumbnail,
    Avatar, DinkCreditBalance
  },
  
  props: {
        profile: {
            type: Object,
            default: null,
        },
  },

  computed: {
    rating() {
        return this.profile?.rating?.rating || '0';
    },

    games() {
        return this.profile?.user?.stats?.matches || '0';
    },

    facility() {
        return this.profile?.user?.facility?.name || 'Fishers';
    },
  }
}
</script>

<style scoped>
    .profile-dink-dollars {
        display: flex;
        justify-content: center;
    }

    .profile-name {
        font-style: normal;
        font-weight: 600;
        font-size: 24px;
        line-height: 28px;
        margin-left: 5px;
    }

    .profile-rating {
        display: flex;
        flex-direction: column;
        padding: 7px 13px;
        background: #F7F7F7;
        --ion-item-background:F7F7F;
        border-radius: 16px;
        width: 100%;
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 16px;
        text-transform: uppercase;
        --inner-padding-end:0px;
        --inner-padding-start:0px;
    }

    ion-item {
        --padding-start: 0px;
    }

    ion-item:last-child {
        --inner-border-width: 0;
    }

    .feed-badges {
        display: flex;
        gap: 3px;
        align-items: center;
        justify-content: center;
        margin-left: 5px;
    }
</style>