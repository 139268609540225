<template>
    <ion-page>            
        <ion-content class="ion-padding">
            <ion-grid class="ion-padding-bottom" v-if="friends.length">
                <FeedBubble v-for="item in friends" 
                    :id="item.friend.id"
                    :title="item.friend.username"
                    :subtitle="item.friend.biography"
                    :icons="{
                        goto: false,
                        msg: true,
                        friendAdd: false,
                    }"
                    @click="handleFriendClick(item.friend.id)"
                ></FeedBubble>
            </ion-grid>
            <ion-grid v-else>    
                <ion-row>
                    <ion-col>
                        <img src="/img/banners/banner-community-friends.png"  class="banner-image">
                    </ion-col>
                </ion-row>
            </ion-grid> 
        </ion-content>
    </ion-page>    
</template>

<script>
import { IonPage, IonNav, IonContent, IonTabs, IonGrid, IonCol, IonRow, IonHeader, 
   IonChip, IonIcon } from '@ionic/vue';
import { ref } from 'vue';
import { mapGetters, mapActions, useStore } from 'vuex';   
import AvatarToolbar from '@/components/ui/AvatarToolbar.vue';
import FeedBubble from '@/components/match/FeedBubble.vue';

export default {
    name: 'Friends',
    components: {
        IonPage, IonNav, AvatarToolbar, IonContent, IonTabs, IonGrid, IonCol, IonRow, IonHeader, 
        IonChip, IonIcon, FeedBubble, mapGetters, mapActions, useStore
    },
    
    data() {
        return {
            friends: []
        }
    },

    async mounted() {
        const friends = ref(null);

        //TODO Add paging to friends
        await this.getFriends.catch(error => {
            return false; 
        });

        this.friends = this.friendsData.data.results;

        return { friends } 
    },

    computed: {
        ...mapGetters({
            friendsData: 'friends/data',
        }),

        ...mapActions({
            getFriends: 'friends/get',
        })
    },

    methods: {
        handleFriendClick(id){
            this.$router.push(`/profile/${id}`);
        }
    }
}
</script>

<style scoped>
.btn-header {
    width: auto;
    --background: $ffffff;
}
</style>