<template>
    <ion-page>
        <ion-header collapse="fade" class="ion-no-border ion-margin-top">
            <ProfileToolbar
                :showMessageBtn=false
                :showEditBtn=false
                defaultHref="/matches/schedule" 
            ></ProfileToolbar>
        </ion-header>            
        <ion-content class="ion-padding">
            <ion-grid>
                <ion-row>
                    <ion-col class="ion-justify-content-center">
                        <QrImage></QrImage>
                    </ion-col>
                </ion-row>
            </ion-grid>
        </ion-content>
    </ion-page>
</template>

<script>
import { IonPage, IonContent, IonGrid, IonCol, IonRow } from '@ionic/vue';
import ProfileToolbar from '@/components/ui/ProfileToolbar.vue';
import QrImage from '@/components/user/QrImage.vue';

export default {
    name: 'CheckIn',
    components: {
        IonPage, IonContent, ProfileToolbar, QrImage, IonGrid, IonCol, IonRow
    }
}
</script>